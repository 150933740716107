exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ambassadors-js": () => import("./../../../src/pages/ambassadors.js" /* webpackChunkName: "component---src-pages-ambassadors-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-waitlist-real-estate-js": () => import("./../../../src/pages/waitlist/real-estate.js" /* webpackChunkName: "component---src-pages-waitlist-real-estate-js" */)
}

